import { pdfjs } from "react-pdf";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PDFViewer from "./components/PDFViewer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DOC_TYPE = {
  PRIVACY_POLICY: "/terms%2Fprivacy.pdf?alt=media",
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <div>TOS</div>,
  },
  {
    path: "/privacy",
    element: <PDFViewer fileUrl={DOC_TYPE.PRIVACY_POLICY} />,
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
