import { FC, useState } from "react";
import { Document, Page } from "react-pdf";
import { baseUrl } from "../config";

interface Props {
  fileUrl: string;
}

const PDFViewer: FC<Props> = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(1);
  const pages = new Array(numPages).fill(0);

  function onDocumentLoadSuccess(param: { numPages: number }) {
    setNumPages(param.numPages);
  }

  return (
    <div
      style={{
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        overflow: "scroll",
        backgroundColor: "#F3F4F6",
        borderRadius: 4,
      }}
    >
      <Document file={baseUrl + fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {pages.map((v, i) => (
          <div style={{ display: "flex", flexDirection: "column" }} key={i}>
            <Page
              width={window.innerWidth}
              pageNumber={i + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </div>
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
